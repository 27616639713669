import { default as ai_45form_45builderzYEL3sx0nxMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/ai-form-builder.vue?macro=true";
import { default as editiDWVgZOM7yMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexMuwPhMgqiIMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/forms/[slug]/index.vue?macro=true";
import { default as indexAKQ6JUZ9pPMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexYcP22ER4TRMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as shareDL5aCkGVD1Meta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as stats85Jm0vmkPvMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissionsgfJ4uG1l7aMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as showhd6bmTyXZ7Meta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/forms/[slug]/show.vue?macro=true";
import { default as guestlpNm7uQNASMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/forms/create/guest.vue?macro=true";
import { default as indexMzpdJWkwwTMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/forms/create/index.vue?macro=true";
import { default as homeHSYpBnh4RXMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/home.vue?macro=true";
import { default as indexGVRPs94dXFMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/index.vue?macro=true";
import { default as login32AR8T3WJsMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/login.vue?macro=true";
import { default as callbackXfpqB3W4ISMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/oauth/callback.vue?macro=true";
import { default as email4GR1ajwuQyMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/password/email.vue?macro=true";
import { default as _91token_93sbx0VHei1zMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/password/reset/[token].vue?macro=true";
import { default as pricingb3fnlJq435Meta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyIzGG9wUxDdMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/privacy-policy.vue?macro=true";
import { default as registerXu9bsEHO9OMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/register.vue?macro=true";
import { default as access_45tokens5nUgUKirQfMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountZ1bF6HRwsrMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/settings/account.vue?macro=true";
import { default as admin51BnSYqvg7Meta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/settings/admin.vue?macro=true";
import { default as billingUIpuMlDUqDMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/settings/billing.vue?macro=true";
import { default as connectionsIQI2jMvippMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/settings/connections.vue?macro=true";
import { default as indexWmoaKdgX37Meta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/settings/index.vue?macro=true";
import { default as passwordrbzY1lIBH4Meta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/settings/password.vue?macro=true";
import { default as profile0wem9L58YrMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/settings/profile.vue?macro=true";
import { default as workspaceqDAYM5bcsRMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/settings/workspace.vue?macro=true";
import { default as settingsO0lzWXbqFyMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/settings.vue?macro=true";
import { default as errorDcCDw8wOURMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/subscriptions/error.vue?macro=true";
import { default as successAS8vSORtkUMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93glUEX7Lo3kMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/templates/[slug].vue?macro=true";
import { default as indexnf5DHtuEYeMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/templates/index.vue?macro=true";
import { default as _91slug_93ds7FEMHukTMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templatesaOGAQ6iAVfMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_93QwehZbP3DlMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionsSpGiXde0IOMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/terms-conditions.vue?macro=true";
import { default as update_45credentialsWjLpTv65kuMeta } from "/codebuild/output/src2872295848/src/OpnForm/client/pages/update-credentials.vue?macro=true";
export default [
  {
    name: "ai-form-builder",
    path: "/ai-form-builder",
    meta: ai_45form_45builderzYEL3sx0nxMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/ai-form-builder.vue")
  },
  {
    name: "forms-slug-edit",
    path: "/forms/:slug()/edit",
    meta: editiDWVgZOM7yMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/forms/[slug]/edit.vue")
  },
  {
    name: "forms-slug",
    path: "/forms/:slug()",
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/forms/[slug]/index.vue")
  },
  {
    name: showhd6bmTyXZ7Meta?.name,
    path: "/forms/:slug()/show",
    meta: showhd6bmTyXZ7Meta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/forms/[slug]/show.vue"),
    children: [
  {
    name: "forms-slug-show",
    path: "",
    meta: indexAKQ6JUZ9pPMeta || {},
    redirect: indexAKQ6JUZ9pPMeta?.redirect,
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/forms/[slug]/show/index.vue")
  },
  {
    name: "forms-slug-show-integrations",
    path: "integrations",
    meta: indexYcP22ER4TRMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue")
  },
  {
    name: "forms-slug-show-share",
    path: "share",
    meta: shareDL5aCkGVD1Meta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/forms/[slug]/show/share.vue")
  },
  {
    name: "forms-slug-show-stats",
    path: "stats",
    meta: stats85Jm0vmkPvMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/forms/[slug]/show/stats.vue")
  },
  {
    name: "forms-slug-show-submissions",
    path: "submissions",
    meta: submissionsgfJ4uG1l7aMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/forms/[slug]/show/submissions.vue")
  }
]
  },
  {
    name: "forms-create-guest",
    path: "/forms/create/guest",
    meta: guestlpNm7uQNASMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/forms/create/guest.vue")
  },
  {
    name: "forms-create",
    path: "/forms/create",
    meta: indexMzpdJWkwwTMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/forms/create/index.vue")
  },
  {
    name: "home",
    path: "/home",
    meta: homeHSYpBnh4RXMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/home.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login32AR8T3WJsMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/login.vue")
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    meta: callbackXfpqB3W4ISMeta || {},
    alias: ["/oauth/:provider/callback"],
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/oauth/callback.vue")
  },
  {
    name: "password-email",
    path: "/password/email",
    meta: email4GR1ajwuQyMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/password/email.vue")
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93sbx0VHei1zMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/password/reset/[token].vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingb3fnlJq435Meta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/pricing.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyIzGG9wUxDdMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/privacy-policy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerXu9bsEHO9OMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/register.vue")
  },
  {
    name: settingsO0lzWXbqFyMeta?.name,
    path: "/settings",
    meta: settingsO0lzWXbqFyMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/settings.vue"),
    children: [
  {
    name: "settings-access-tokens",
    path: "access-tokens",
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/settings/access-tokens.vue")
  },
  {
    name: "settings-account",
    path: "account",
    meta: accountZ1bF6HRwsrMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/settings/account.vue")
  },
  {
    name: "settings-admin",
    path: "admin",
    meta: admin51BnSYqvg7Meta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/settings/admin.vue")
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billingUIpuMlDUqDMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/settings/billing.vue")
  },
  {
    name: "settings-connections",
    path: "connections",
    meta: connectionsIQI2jMvippMeta || {},
    alias: ["/settings/connections/callback/:service"],
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/settings/connections.vue")
  },
  {
    name: "settings",
    path: "",
    meta: indexWmoaKdgX37Meta || {},
    redirect: indexWmoaKdgX37Meta?.redirect,
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/settings/index.vue")
  },
  {
    name: "settings-password",
    path: "password",
    meta: passwordrbzY1lIBH4Meta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/settings/password.vue")
  },
  {
    name: "settings-profile",
    path: "profile",
    meta: profile0wem9L58YrMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/settings/profile.vue")
  },
  {
    name: "settings-workspace",
    path: "workspace",
    meta: workspaceqDAYM5bcsRMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/settings/workspace.vue")
  }
]
  },
  {
    name: "subscriptions-error",
    path: "/subscriptions/error",
    meta: errorDcCDw8wOURMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/subscriptions/error.vue")
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    meta: successAS8vSORtkUMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/subscriptions/success.vue")
  },
  {
    name: "templates-slug",
    path: "/templates/:slug()",
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/templates/[slug].vue")
  },
  {
    name: "templates",
    path: "/templates",
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/templates/index.vue")
  },
  {
    name: "templates-industries-slug",
    path: "/templates/industries/:slug()",
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/templates/industries/[slug].vue")
  },
  {
    name: "templates-my-templates",
    path: "/templates/my-templates",
    meta: my_45templatesaOGAQ6iAVfMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/templates/my-templates.vue")
  },
  {
    name: "templates-types-slug",
    path: "/templates/types/:slug()",
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/templates/types/[slug].vue")
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    meta: terms_45conditionsSpGiXde0IOMeta || {},
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/terms-conditions.vue")
  },
  {
    name: "update-credentials",
    path: "/update-credentials",
    component: () => import("/codebuild/output/src2872295848/src/OpnForm/client/pages/update-credentials.vue")
  }
]